import React, { useState } from "react";
import "./Galeria.css";
import Image1 from "../../assets/EssenciaKozpontMegnyito.jpg";
import Image2 from "../../assets/EssenciaKozpontMegnyito-2.jpg";
import Image3 from "../../assets/EssenciaKozpontMegnyito-3.jpg";
import Image4 from "../../assets/EssenciaKozpontMegnyito-4.jpg";
import Image5 from "../../assets/kozpont_emelet_love.jpg";
import Image6 from "../../assets/kozpont_emelet_love_2.jpg";
import Image7 from "../../assets/krediva_konzultacio.jpg";

function Galeria() {
  const [visibleDiv, setVisibleDiv] = useState('div-osszes');

  const handleButtonClick = (divId) => {
    setVisibleDiv(divId);
  };

  return (
    <div className="galeria">
      <h1>Galéria</h1>
      <div className="oldalak-container">
        <div className="bal">
          <button onClick={() => handleButtonClick('div-osszes')}>Összes terem</button>
          <button onClick={() => handleButtonClick('div2')}>Kandallós terem</button>
          <button onClick={() => handleButtonClick('div3')}>Nagyterem</button>
          <button onClick={() => handleButtonClick('div4')}>44-es terem</button>
          <button onClick={() => handleButtonClick('div5')}>Földszinti terem</button>
          <button onClick={() => handleButtonClick('div6')}>Emeleti terem</button>
          <button onClick={() => handleButtonClick('div7')}>Tetőtéri iroda</button>
        </div>

        <div className="jobb">
          {visibleDiv === 'div-osszes' && <div className="div-osszes">
            Összes kép
            </div>}
          {visibleDiv === 'div2' && <div className="div2">
            Tartalom 2
            </div>}
          {visibleDiv === 'div3' && <div className="div3">
            Tartalom 3
            </div>}
            {visibleDiv === 'div4' && <div className="div4">
            Tartalom 4
            </div>}
            {visibleDiv === 'div5' && <div className="div5">
            Tartalom 5
            </div>}
            {visibleDiv === 'div6' && <div className="div6">
            Tartalom 6
            </div>}
            {visibleDiv === 'div7' && <div className="div7">
            Tartalom 7
            </div>}
        </div>
      </div>
    </div>
  );
}

export default Galeria;