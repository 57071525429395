import React from "react";
import "./Rolunk.css";
import RolunkImg from "../../assets/rolunk_img_2.png";
import Velemenyek from "../Velemenyek";

function Rolunk() {
  return (
    <>
      <div className="rolunk">
        <div className="rolunk-container">
          <div className="rolunk-img">
            <img src={RolunkImg} alt="rolunkImg" />
          </div>

          <div className="rolunk-text">
            <h1>Rólunk</h1>
            <h2>Élmények és emlékek teremtése</h2>
            <p>
              Célunk, hogy a terembérlés Győr városában ne csupán egy egyszerű
              szolgáltatás legyen számodra, hanem egy teljes élmény, ahol minden
              részlet a kényelmed és igényeid szerint van kialakítva.
              Motivációnk, hogy minden ügyfelünk számára biztosítsuk a maximális
              rugalmasságot és minőséget, legyen szó akár alkalmi, akár
              rendszeres bérlésről. Fontosnak tartjuk, hogy helyszíneink ne csak
              funkcionálisan megfelelők legyenek, hanem otthonos és inspiráló
              környezetet biztosítsanak minden esemény és rendezvény számára.
              Hiszünk abban, hogy a figyelem a részletekre, a személyre szabott
              megoldások és a professzionális hozzáállás kulcsfontosságúak
              ahhoz, hogy minden ügyfelünk elégedetten távozzon, és szívesen
              térjen vissza hozzánk.
            </p>
          </div>
        </div>
      </div>
      <Velemenyek />
    </>
  );
}

export default Rolunk;
