import React from 'react'
import './Fooldal.css';
import Navbar from '../Navbar';
import HeroImg from '../../assets/EssenciaKozpontMegnyito-2.jpg';

const scrollToSection = (id) => {
  const section = document.querySelector(id);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      const offset = 70;
      const sectionTop = section.getBoundingClientRect().top;

      window.scrollBy({
        top: sectionTop - offset,
        behavior: 'smooth',
      });
    }, 10);
  }
};

function Fooldal() {
  return (
    <div className='fooldal'>
      <div className='hero'>
                <img alt='HeroImg' src={HeroImg}/>
                <div className='hero-text'>
                    <h1 id='home-text'>Terembérlés Győrben</h1>
                    <p>Bérelj termet nálunk!</p>
                    <button className='show' onClick={() => scrollToSection('#termek')}>Termeink</button>
                </div>
            </div>
    </div>
  )
}

export default Fooldal