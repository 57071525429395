import React from "react";
import "./Termek.css";
import KandallosImg from "../../assets/EssenciaKozpontMegnyito.jpg";
import { Link } from "react-router-dom";

function Termek() {
  return (
    <div className="termek">
      <h3>Nézd meg</h3>
      <h1>termeinket!</h1>
      <div className="container">

        <div className="kandallos">
          <img src={KandallosImg} />
          <div className="cim">
            <h2>Kandallós terem</h2>
          </div>
          <div className="szoveg">
            <p>
              A Kandallós terem egy kellemes, 25 négyzetméteres helyiség,
              amely ideális helyszínt biztosíthat például személyes
              konzultációkhoz és kisebb létszámú beszélgetésekhez.
            </p>
            <Link to={"/kandallos-terem"} target="_blank">
              <button className="btn-erdekel">Több információ</button>
            </Link>
          </div>
        </div>

        <div className="negynegy">
          <img src={KandallosImg} />
          <div className="cim">
            <h2>44-es terem</h2>
          </div>
          <div className="szoveg">
            <p>
              A 44-es terem egy hangulatos, 20 négyzetméteres helyiség, amely
              ideális helyszínt biztosíthat például személyes
              konzultációkhoz és kisebb létszámú beszélgetésekhez.
            </p>
            <Link to={"/negynegy-terem"} target="_blank">
              <button className="btn-erdekel">Több információ</button>
            </Link>
          </div>
        </div>

        <div className="nagyterem">
          <img src={KandallosImg} />
          <div className="cim">
            <h2>Nagyterem</h2>
            <p>
              A Nagyterem egy tágas, 70 négyzetméteres helyiség, amely
              ideális helyszínt biztosíthat például csoportos
              foglalkozásokra és mindenféle mozgásra, például yogára.
            </p>
            <Link to={"/nagyterem"} target="_blank">
              <button className="btn-erdekel">Több információ</button>
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Termek;

/*
<h1>Termeink</h1>
      <div className="container">

        <div className="kandallos wrapper">
          <div className="single-card">
            <div className="img-area">
              <img src={KandallosImg} alt="foldszintImg" />
              <div className="info">
                <h3>Kandallós terem</h3>
                <p>
                  A Kandallós terem egy kellemes, 25 négyzetméteres helyiség,
                  amely ideális helyszínt biztosíthat például személyes
                  konzultációkhoz és kisebb létszámú beszélgetésekhez.
                </p>
                <Link to={"/kandallos-terem"} target="_blank">
                <button className="btn-erdekel">Érdekel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="negynegy wrapper">
          <div className="single-card">
            <div className="img-area">
              <img src={KandallosImg} alt="foldszintImg" />
              <div className="info">
                <h3>44-es terem</h3>
                <p>
                  A 44-es terem egy hangulatos, 20 négyzetméteres helyiség, amely
                  ideális helyszínt biztosíthat például személyes
                  konzultációkhoz és kisebb létszámú beszélgetésekhez.
                </p>
                <Link to={"/negynegy-terem"} target="_blank">
                <button className="btn-erdekel">Érdekel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="nagyterem wrapper">
          <div className="single-card">
            <div className="img-area">
              <img src={KandallosImg} alt="foldszintImg" />
              <div className="info">
                <h3>Nagyterem</h3>
                <p>
                  A Nagyterem egy tágas, 70 négyzetméteres helyiség, amely
                  ideális helyszínt biztosíthat például csoportos
                  foglalkozásokra és mindenféle mozgásra, például yogára.
                </p>
                <Link to={"/nagyterem"} target="_blank">
                <button className="btn-erdekel">Érdekel</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
*/