export const MenuItems = [
    {
        title: "Szolgáltatások",
        url: "#szolgaltatasok",
        cName: "nav-links",
        icon: "fa-solid fa-list-check",
    },
    {
        title: "Termek/Árak",
        url: "#termek",
        cName: "nav-links",
        icon: "fa-solid fa-couch",
    },
    {
        title: "Galéria",
        url: "#galeria",
        cName: "nav-links",
        icon: "fa-solid fa-images",
    },
    {
        title: "Foglalás",
        url: "#foglalas",
        cName: "nav-links",
        icon: "fa-regular fa-calendar-days",
    }
]