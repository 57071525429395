import React, { useState } from "react";
import "./Footer.css";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

function Footer() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  function handleSubmit(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_teremberlesgyor",
        "teremberlesgyor_hirlevel",
        e.target,
        "Few2XsX1s-XWQMfPL"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Feliratkozás sikeres!");
          setFormData({
            email: "",
          });
          setIsChecked(false);
        },
        (error) => {
          console.log(error.text);
          setMessage("Hiba történt a feliratkozás közben.");
        }
      );
  }

  return (
    <div className="footer">
      <div className="top">
        <div className="bal">
          <h1>teremberlesgyor.hu</h1>
          <p>Terembérlés Győr belvárosában</p>
          <h3>Kövess minket!</h3>
          <a href="https://www.facebook.com/teremberlesgyor" target="_blank">
            <i className="fa-brands fa-facebook-square"></i>
          </a>
          <a
            href="https://www.instagram.com/essencia_elmeny_kozpont/"
            target="_blank"
          >
            <i className="fa-brands fa-instagram-square"></i>
          </a>
        </div>

        <div className="hirlevel">
          <h3>Hírlevél</h3>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="*Email-cím"
              value={formData.email}
              onChange={handleChange}
              className="email"
              required
            />
            <br />
            <div className="check-container">
              <input
                type="checkbox"
                name="nyilatkozat"
                className="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                required
              />
              <p>
                Elfogadom az{" "}
                <a href="/adatkezelesi-nyilatkozat">Adatkezelési Nyilatkozatot!</a>
              </p>
            </div>
            <br />
            <button type="submit" disabled={!isChecked}>
              Feliratkozás
            </button>
          </form>
        </div>

        <div className="elerhetosegek">
          <h3>Elérhetőségeink</h3>
          <p>
            <i className="fa-solid fa-phone"></i>+36 20 378 0320
          </p>
          <p>
            <i className="fa-solid fa-envelope"></i>info@teremberlesgyor.hu
          </p>
          <p>
            <i className="fa-solid fa-location-dot"></i>9022 Győr, Czuczor Gergely utca 18-24.
          </p>
        </div>
      </div>

      <hr />
      <div className="bottom">
        <div>
          <p>
            © 2024 <a>teremberlesgyor</a> | Minden jog fenntartva
          </p>
        </div>
        <div>
          <Link to="/adatkezelesi-nyilatkozat" target="_blank">Adatkezelési Nyilatkozat</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;