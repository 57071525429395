import React from 'react';
import "./Adatkezeles.css";

function Adatkezeles() {
  return (
    <div className='adatkezeles'>
      <h1>teremberlesgyor.hu</h1>
      <h2>Adatkezelési Nyilatkozat</h2>
      <p>
        <h3>Bevezetés</h3>
        <br />
        Az adatkezelési nyilatkozat célja, hogy tájékoztassa Önt az általunk végzett adatkezelési tevékenységekről, az adatkezelés céljairól, jogalapjáról, az adatkezelés során követett elvekről, valamint az Ön jogairól és jogorvoslati lehetőségeiről. Az alábbiakban részletesen bemutatjuk, milyen adatokat gyűjtünk Öntől, hogyan használjuk fel ezeket az adatokat, és hogyan gondoskodunk az adatok biztonságáról.
        <br /><br />
        <h3>Adatkezelési tevékenységek</h3>
        <br />
        <u>Az általunk kezelt adatok</u>
        <br />
        Az alábbi adatokat kérjük be Öntől a foglalási űrlap kitöltése során:
        <ul>
          <li><strong>Név</strong>: Az Ön teljes neve, amely szükséges a foglalás azonosításához és a kapcsolatfelvételhez.</li>
          <li><strong>Telefonszám</strong>: Az Ön telefonszáma, amelyen szükség esetén elérhetjük Önt a foglalással kapcsolatban.</li>
          <li><strong>Email-cím</strong>: Az Ön email-címe, amelyre a foglalás visszaigazolását és egyéb kapcsolódó információkat küldjük.</li>
          <li><strong>Dátum-tól és Dátum-ig</strong>: Az Ön által megadott időszak, amelyre a termet szeretné lefoglalni.</li>
          <li><strong>Tevékenység</strong>: Az Ön által tervezett tevékenység rövid leírása, amely segít számunkra a terem megfelelő előkészítésében.</li>
          <li><strong>Létszám</strong>: Az Ön által tervezett résztvevők száma, amely fontos információ a terem kapacitásának megfelelő kihasználásához.</li>
          <li><strong>Megjegyzés</strong>: Egyéb megjegyzések vagy különleges kérések, amelyek segítik a foglalás zavartalan lebonyolítását.</li>
          <li><strong>Terem</strong>: Az Ön által kiválasztott terem neve, amelyet lefoglalni kíván.</li>
        </ul>
        <br />
        <u>Az adatok felhasználásának célja</u>
        <br />
        Az általunk gyűjtött adatokat az alábbi célokra használjuk fel:
        <ul>
          <li><strong>Foglalások kezelése és visszaigazolása</strong>: Az Ön által megadott adatokat a foglalás rögzítéséhez, kezeléséhez és visszaigazolásához használjuk fel.</li>
          <li><strong>Kapcsolatfelvétel</strong>: Az Önnel való kapcsolatfelvétel érdekében szükség esetén az Ön telefonszámát és email-címét használjuk.</li>
          <li><strong>Szolgáltatásaink javítása</strong>: Az Ön által megadott adatok segítenek számunkra szolgáltatásaink fejlesztésében és testreszabásában.</li>
          <li><strong>Marketing célú kommunikáció</strong>: Az Ön beleegyezésével az Ön által megadott email-címre és telefonszámra akciókat, hirdetményeket és tájékoztatókat küldhetünk.</li>
        </ul>
        <br />
        <u>Adatkezelés jogalapja</u>
        <br />
        Az adatkezelés jogalapja az Ön hozzájárulása, amelyet az űrlap kitöltése, az Adatkezelési Nyilatkozat elfogadása és a "Küldés" gomb megnyomása során ad meg számunkra. Az adatkezelés jogalapját az Európai Unió Általános Adatvédelmi Rendelete (GDPR) biztosítja.
        <br /><br />
        <h3>Az adatok biztonsága</h3>
        <br />
        Az Ön adatait bizalmasan kezeljük, és megteszünk minden szükséges technikai és szervezési intézkedést annak érdekében, hogy az adatokat megvédjük a jogosulatlan hozzáféréstől, módosítástól, nyilvánosságra hozataltól vagy törléstől.
        <br /><br />
        <h3>Adattovábbítás</h3>
        <br />
        Az Ön adatait harmadik fél számára nem adjuk át, kivéve, ha erre jogszabály kötelez minket, vagy ha Ön kifejezetten hozzájárult az adattovábbításhoz.
        <br /><br />
        <h3>Az Ön jogai</h3>
        <br />
        Ön jogosult arra, hogy tájékoztatást kérjen az általunk kezelt adatokról, kérje azok helyesbítését, törlését vagy kezelésének korlátozását. Jogosult továbbá arra is, hogy tiltakozzon az adatok kezelése ellen, valamint hogy adatait egy másik adatkezelőhöz továbbítsa (adathordozhatóság joga).
        <br /><br />
        <h3>Kapcsolat</h3>
        <br />
        Amennyiben bármilyen kérdése, észrevétele van adatkezelési tevékenységeinkkel kapcsolatban, vagy élni kíván jogai bármelyikével, kérjük, lépjen kapcsolatba velünk az alábbi elérhetőségeken:
        <ul>
          <li><strong>Email-cím</strong>: info@teremberlesgyor.hu</li>
          <li><strong>Telefonszám</strong>: +36 20 378 0320</li>
          <li><strong>Cím</strong>: 9022 Győr, Schweidel utca 5.</li>
        </ul>
        <br />
        <h3>Záró rendelkezések</h3>
        <br />
        Az adatkezelési nyilatkozatunk változtatásának jogát fenntartjuk. Az esetleges módosításokról megfelelő időben értesítjük Önt.
        <br /><br />
        <h3>Hozzájárulás a marketing célú kommunikációhoz</h3>
        <br />
        Az Ön által megadott email-címre és telefonszámra időnként akciókat, hirdetményeket és tájékoztatókat küldhetünk, amelyekről Ön bármikor leiratkozhat.
        <br />
        <br />
        <h3>Köszönjük, hogy igénybe veszi szolgáltatásainkat és megbízik bennünk adatai kezelésével!</h3>
      </p>
    </div>
  )
}

export default Adatkezeles;