import React from "react";
import "./Kapcsolat.css";

function Kapcsolat() {
  return (
    <div className="kapcsolat">
      <h2>Lépj velünk</h2>
      <h1>kapcsolatba!</h1>
      <div className="felso-container">
        <div className="email folt">
          <i class="fa-solid fa-envelope"></i>
          <h3>Email-cím</h3>
          <p>info@teremberlesgyor.hu</p>
        </div>
        <div className="telefon folt">
          <i class="fa-solid fa-phone"></i>
          <h3>Telefonszám</h3>
          <p>+36 20 378 0320</p>
        </div>
        <div className="cim folt">
          <i class="fa-solid fa-location-dot"></i>
          <h3>Cím</h3>
          <p>9022 Győr, Czuczor Gergely utca 18-24.</p>
        </div>
      </div>
      <div className="also-container">
        <a href="https://www.facebook.com/teremberlesgyor" target="_blank">
        <div className="facebook folt">
          <i class="fa-brands fa-square-facebook"></i>
          <h3>Facebook</h3>
        </div>
        </a>
        <a href="https://www.instagram.com/essencia_elmeny_kozpont/" target="_blank">
        <div className="instagram folt">
          <i class="fa-brands fa-square-instagram"></i>
          <h3>Instagram</h3>
        </div>
        </a>
      </div>
    </div>
  );
}

export default Kapcsolat;
