import React from "react";
import "./Szolgaltatasok.css";

function Szolgaltatasok() {
  return (
    <div className="szolgaltatasok">
      <div className="services-title">
        <h2>Válassz</h2>
        <h1>szolgáltatásaink</h1>
        <h2 className="services-title-bottom">közül!</h2>
      </div>

      <div className="services-cards">
        <div className="services-box">
          <i className="fa-solid fa-couch icon"></i>
          <h3>Terembérlés</h3>
          <p>
            Termembérlés esetén két fő típusú lehetőség kínálkozik:{" "}
            <b>alkalmi</b> és <b>rendszeres</b> bérlés. Az alkalmi bérlés
            lehetőséget biztosít arra, hogy rövid időtartamra, például egy-egy
            esemény vagy rendezvény céljából vedd igénybe a választott termet.
            Ez ideális megoldás lehet egy gyorsan szervezett összejövetelre,
            workshopra vagy kisebb események lebonyolítására. Másrészt, a
            rendszeres bérlés lehetőséget ad arra, hogy hosszabb távra
            biztosítsuk a kiválasztott termet, például irodai munkavégzéshez,
            rendszeres workshopok tartásához vagy más gyakran ismétlődő
            tevékenységek számára. Mindkét lehetőség célja, hogy kényelmes és
            rugalmas környezetet biztosítson az ügyfelek számára, megfelelve az
            egyedi igényeknek és szempontoknak.
          </p>
        </div>

        <div className="services-box">
          <i class="fa-solid fa-champagne-glasses icon"></i>
          <h3>Rendezvényszervezés</h3>
          <p>Szervezd meg velünk!</p>
        </div>

        <div className="services-box">
          <i className="fa-solid fa-handshake-angle icon"></i>
          <h3>Asszisztencia</h3>
          <p>Segítségre lenne szükséged? Mi azt is biztosítunk!</p>
        </div>
      </div>
    </div>
  );
}

export default Szolgaltatasok;
