import React from "react";
import "./styles.css";
import Fooldal from "./components/routes/Fooldal";
import Szolgaltatasok from "./components/routes/Szolgaltatasok";
import Rolunk from "./components/routes/Rolunk";
import Termek from "./components/routes/Termek";
import Galeria from "./components/routes/Galeria";
import Kapcsolat from "./components/routes/Kapcsolat";
import Foglalas from "./components/routes/Foglalas";
import Adatkezeles from "./components/routes/Adatkezeles";
import Footer from "./components/Footer";
import Navbar from './components/Navbar';
import Kandallos from './components/termek/Kandallos';
import Negynegyes from './components/termek/Negynegyes';
import Nagyterem from './components/termek/Nagyterem';
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <>
            <Navbar />
            <div id="fooldal">
              <Fooldal />
            </div>
            <div id="szolgaltatasok">
              <Szolgaltatasok />
            </div>
            <div id="rolunk">
              <Rolunk />
            </div>
            <div id="termek">
              <Termek />
            </div>
            <div id="galeria">
              <Galeria />
            </div>
            <div id="foglalas">
              <Foglalas />
            </div>
            <div id="kapcsolat">
              <Kapcsolat />
            </div>
            <Footer />
          </>
        } />
        <Route path="/adatkezelesi-nyilatkozat" element={<Adatkezeles />} />
        <Route path="/kandallos-terem" element={<Kandallos />} />
        <Route path="/negynegy-terem" element={<Negynegyes />} />
        <Route path="/nagyterem" element={<Nagyterem />} />
      </Routes>
    </div>
  );
}

export default App;