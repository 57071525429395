import React from 'react'
import "./Nagyterem.css"

function Nagyterem() {
  return (
    <div className='nagyterem'>
        <h1>Nagyterem</h1>
    </div>
  )
}

export default Nagyterem
