import React from "react";
import "./Velemenyek.css";
import Logo1 from "../assets/orchidea_logo.png";

function Velemenyek() {
  return (
    <div className="velemenyek">
      <h1>Mit mondanak rólunk?</h1>

      <div className="container">
        <a
          target="_blank"
          href="https://www.google.com/search?sa=X&sca_esv=6ecc94ad967df55f&sca_upv=1&rlz=1C1KNTJ_huHU1080HU1080&tbm=lcl&sxsrf=ADLYWILhHULCPZDNv4G2GshtpkDj2Ll2WQ:1719393292459&q=Essencia+%C3%89lm%C3%A9ny+K%C3%B6zpont+V%C3%A9lem%C3%A9nyek&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLUwNDMwNTW0NDYxNTMyN7M0MNzAyPiKUd21uDg1LzkzUeFwZ07u4ZV5lQreh7dVFeTnlSiEHV6ZkwoWS81exEqsSgAc_dWXbwAAAA&rldimm=15816055193456276901&hl=hu-HU&ved=2ahUKEwjfibST9_iGAxUz9AIHHQ6PAfYQ9fQKegQINBAF&biw=1620&bih=919&dpr=2#lkt=LocalPoiReviews"
        >
          <div className="box box-bal">
            <div className="fejlec">
              <img src={Logo1} alt="orchideaLogo" />
              <h2>Magyar Veronika</h2>
            </div>
            <div className="csillagok">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <p>
              Kislányom első születésnapját tartottuk és imádtuk minden
              pillanatát. Edina nagyon figyelmes volt, bármilyen kérésünk/
              kérdésünk volt ott segített ahol csak tudott. Csak ajánlani tudom
              annak aki egy nyugodt, barátságos és egyben stílusos helyen
              szeretne egy kellemes délutánt tölteni.
            </p>
          </div>
        </a>

        <a
          target="_blank"
          href="https://www.google.com/search?sa=X&sca_esv=6ecc94ad967df55f&sca_upv=1&rlz=1C1KNTJ_huHU1080HU1080&tbm=lcl&sxsrf=ADLYWILhHULCPZDNv4G2GshtpkDj2Ll2WQ:1719393292459&q=Essencia+%C3%89lm%C3%A9ny+K%C3%B6zpont+V%C3%A9lem%C3%A9nyek&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLUwNDMwNTW0NDYxNTMyN7M0MNzAyPiKUd21uDg1LzkzUeFwZ07u4ZV5lQreh7dVFeTnlSiEHV6ZkwoWS81exEqsSgAc_dWXbwAAAA&rldimm=15816055193456276901&hl=hu-HU&ved=2ahUKEwjfibST9_iGAxUz9AIHHQ6PAfYQ9fQKegQINBAF&biw=1620&bih=919&dpr=2#lkt=LocalPoiReviews"
        >
          <div className="box box-jobb">
            <div className="fejlec">
              <img src={Logo1} alt="orchideaLogo" />
              <h2>Gerzsany Robi</h2>
            </div>
            <div className="csillagok">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <p>
              Szépen kialakított hely, több tíz főt befogadó nagyobb és kisebb
              privát termekkel. A szombati nap amikor itt jártam, a színház
              előtti téren rövid ideig zaj volt, addig az emeleti ablakot
              becsuktuk. Egyébként a hellyel minden rendben: több toilet van az
              emeleten és a földszinten is. Az alagsorban nem jártam.
            </p>
          </div>
        </a>
      </div>

      <div className="box-lent-div">
        <a
          target="_blank"
          href="https://www.google.com/search?sa=X&sca_esv=6ecc94ad967df55f&sca_upv=1&rlz=1C1KNTJ_huHU1080HU1080&tbm=lcl&sxsrf=ADLYWILhHULCPZDNv4G2GshtpkDj2Ll2WQ:1719393292459&q=Essencia+%C3%89lm%C3%A9ny+K%C3%B6zpont+V%C3%A9lem%C3%A9nyek&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLUwNDMwNTW0NDYxNTMyN7M0MNzAyPiKUd21uDg1LzkzUeFwZ07u4ZV5lQreh7dVFeTnlSiEHV6ZkwoWS81exEqsSgAc_dWXbwAAAA&rldimm=15816055193456276901&hl=hu-HU&ved=2ahUKEwjfibST9_iGAxUz9AIHHQ6PAfYQ9fQKegQINBAF&biw=1620&bih=919&dpr=2#lkt=LocalPoiReviews"
        >
          <div className="box box-lent">
            <div className="fejlec">
              <img src={Logo1} alt="orchideaLogo" />
              <h2>dr Kokas Edit Anna</h2>
            </div>
            <div className="csillagok">
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
              <i class="fa-solid fa-star"></i>
            </div>
            <p>
            Csodaszép helyszín, szuper árak, rugalmas, korrekt és kedves
            vezető! Szeretettel ajánlom!
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Velemenyek;
