import React from 'react'
import "./Negynegyes.css"

function Negynegyes() {
  return (
    <div className='negynegy'>
      <h1>Kandallós terem</h1>
    </div>
  )
}

export default Negynegyes