import React, { useState } from "react";
import "./Foglalas.css";
import emailjs from "emailjs-com";

function Foglalas() {
  const [formData, setFormData] = useState({
    nev: "",
    telefon: "",
    email: "",
    "datum-tol": "",
    "datum-ig": "",
    tevekenyseg: "",
    letszam: "",
    megjegyzes: "",
    terem: "",
  });

  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  function handleSubmit(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_teremberlesgyor",
        "template_teremberlesgyor",
        e.target,
        "Few2XsX1s-XWQMfPL"
      )
      .then(
        (result) => {
          console.log(result.text);
          setMessage("Foglalás sikeresen elküldve!");
          setFormData({
            nev: "",
            telefon: "",
            email: "",
            "datum-tol": "",
            "datum-ig": "",
            tevekenyseg: "",
            letszam: "",
            megjegyzes: "",
            terem: "",
          });
          setIsChecked(false);
        },
        (error) => {
          console.log(error.text);
          setMessage("Hiba történt a foglalás küldése közben.");
        }
      );
  }

  return (
    <div className="foglalas">
      <div className="container">
        <div className="bal">
          <h1>Foglalás</h1>
          <p>
            Foglald le az általad választott termünket online! A "Küldés" gomb
            megnyomása után még nem biztos, hogy az általad választott terem és
            időpont a tiéd, ezért arra kérünk, hogy figyeld az emailben küldött
            válaszunkat!
          </p>
          {message && <p className="message">{message}</p>}
        </div>
        <div className="jobb">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="nev"
              placeholder="*Név"
              value={formData.nev}
              onChange={handleChange}
              className="nev"
              required
            />
            <br />
            <input
              type="text"
              name="telefon"
              placeholder="*Telefonszám"
              value={formData.telefon}
              onChange={handleChange}
              className="telefon"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="*Email-cím"
              value={formData.email}
              onChange={handleChange}
              className="email"
              required
            />
            <br />
            <input
              type="datetime-local"
              name="datum-tol"
              value={formData["datum-tol"]}
              onChange={handleChange}
              className="datum tol"
              required
            />
            <input
              type="datetime-local"
              name="datum-ig"
              value={formData["datum-ig"]}
              onChange={handleChange}
              className="datum ig"
              required
            />
            <br />
            <textarea
              name="tevekenyseg"
              rows="4"
              placeholder="*Tevékenység rövid leírása"
              value={formData.tevekenyseg}
              onChange={handleChange}
              className="tevekenyseg"
              required
            ></textarea>
            <br />
            <select
              id="terem"
              name="terem"
              value={formData.terem}
              onChange={handleChange}
              className="terem"
              required
            >
              <option value="">*Válassz egy termet!</option>
              <option value="kandallos">Kandallós terem</option>
              <option value="nagyterem">Nagyterem</option>
              <option value="44-es">44-es terem</option>
              <option value="foldszint">Földszinti terem</option>
              <option value="emelet">Emeleti terem</option>
              <option value="tetoter">Tetőtéri iroda</option>
              <option value="tetoter">Egész Központ</option>
            </select>
            <input
              type="number"
              name="letszam"
              placeholder="*Tervezett létszám"
              value={formData.letszam}
              onChange={handleChange}
              className="letszam"
              required
            />
            <br />
            <textarea
              name="megjegyzes"
              rows="4"
              placeholder="Egyéb megjegyzés"
              value={formData.megjegyzes}
              onChange={handleChange}
              className="megjegyzes"
            ></textarea>
            <br />
            <div className="check-container">
              <input
                type="checkbox"
                name="nyilatkozat"
                className="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                required
              />
              <p>
                Elfogadom az{" "}
                <a href="/adatkezelesi-nyilatkozat">
                  Adatkezelési Nyilatkozatot!
                </a>
              </p>
            </div>
            <br />
            <button type="submit" disabled={!isChecked}>
              Küldés
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Foglalas;