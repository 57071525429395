import React from 'react';
import "./Kandallos.css";

function Kandallos() {
  return (
    <div className='kandallos'>
      <h1>Kandallós terem</h1>
    </div>
  )
}

export default Kandallos