import { Component } from "react";
import "./Navbar.css";
import { MenuItems } from "./MenuItems";
import { Link } from "react-router-dom";

class Navbar extends Component {
  state = { clicked: false };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });

      setTimeout(() => {
        const offset = 70;
        const sectionTop = section.getBoundingClientRect().top;

        window.scrollBy({
          top: sectionTop - offset,
          behavior: "smooth",
        });
      }, 10);
    }
  };

  render() {
    return (
      <nav className="NavbarItems">
        <h1 className="navbar-logo" onClick={this.scrollToTop}>
          teremberlesgyor.hu
        </h1>

        <div className="menu-icons" onClick={this.handleClick}>
          <i
            className={
              this.state.clicked ? "fa-solid fa-times" : "fa-solid fa-bars"
            }
          ></i>
        </div>

        <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <a
                  className={item.cName}
                  href={item.url}
                  onClick={(e) => {
                    e.preventDefault();
                    this.scrollToSection(item.url);
                  }}
                >
                  <i className={item.icon}></i>
                  {item.title}
                </a>
              </li>
            );
          })}
          <li>
            <button
              onClick={() => {
                this.scrollToSection("#kapcsolat");
              }}
            >
              Kapcsolat
            </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;